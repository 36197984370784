






import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        LayoutGuest: () => import("@/layouts/presets/guest.vue"),
    },
})
export default class PageGuest extends Vue {}
